import React, { useEffect, useState } from 'react'
import { SelectInputReferentiel, SelectReferentielProps } from 'plateforme/components'
import ProfilEntreprise from 'plateforme/store/types/profilEntreprise'
import { useGetProfilEntrepriseQuery } from 'assureur/store/apis/profilEntrepriseApi'

export default function SelectInputNatureEvenement({
  profilEntreprise,
  ancienCodeNature,
  ...restProps
}: SelectReferentielProps<'naturesEvenement'> & { profilEntreprise?: ProfilEntreprise; ancienCodeNature?: string }) {
  const { data: profilEntrepriseInternal, isLoading } = useGetProfilEntrepriseQuery(undefined, {
    skip: profilEntreprise === undefined,
  })
  const profilEntrepriseSelected = profilEntreprise ?? profilEntrepriseInternal

  const [filtre, setFiltre] = useState<string[]>(ancienCodeNature ? [ancienCodeNature] : [])
  useEffect(() => {
    if (!isLoading) {
      const typesFichesConclusions = profilEntrepriseSelected?.parametrage?.typesFichesConclusions
      if (typesFichesConclusions) {
        setFiltre([...filtre, ...Object.keys(typesFichesConclusions)])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, profilEntrepriseSelected, setFiltre])

  const filtreList = filtre?.length === 0 ? undefined : filtre

  return (
    <SelectInputReferentiel
      {...restProps}
      id="nature-evenement"
      label="Nature d'événement"
      referentielName="naturesEvenement"
      codeFilter={filtreList}
      isLoadingExternal={isLoading}
      keepSortServer
    />
  )
}
