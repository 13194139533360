import React, { useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Grid } from '@mui/material'
import { EditTextField } from 'plateforme/components'
import { clearRapportConseilErrors } from 'plateforme/services/utils'

// Description : input number min, max et comentaire
interface DommageFieldNumericProps {
  name: string
  code: string
  readOnly?: boolean
  disabled?: boolean
}

export default function RapportConseilFieldPourcentageFourchette({
  name,
  code,
  readOnly,
  disabled,
}: DommageFieldNumericProps) {
  const { clearErrors, control, getFieldState, resetField, setError, watch } = useFormContext()

  const fieldName = `dommages.${code}.rapportConseil`
  const errorPath = `${fieldName}._error`
  const { error: groupError } = getFieldState(errorPath)

  // remplace l'erreur global (dommages.${code}.rapportConseil) par l'erreur sur le commentaire + min + max
  useEffect(() => {
    if (groupError?.message) {
      clearErrors(errorPath)
      setError(`${fieldName}.commentaire`, groupError)
      setError(`${fieldName}.min`, { type: 'global' })
      setError(`${fieldName}.max`, { type: 'global' })
    }
  }, [clearErrors, errorPath, fieldName, groupError, setError])

  return (
    <Grid container direction="row" alignItems="flex-start">
      <Grid item xs={6} md={6} display="flex" alignItems="flex-start">
        <Controller
          name={`${name}.min`}
          control={control}
          render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
            <EditTextField
              value={value}
              id={code}
              type="number"
              label="Valeur minimum numérique entière (%)"
              onBlur={onBlur}
              onChange={(e) => {
                clearRapportConseilErrors(fieldName, resetField, watch, clearErrors)
                onChange(e)
              }}
              fieldError={error}
              readOnly={readOnly}
              disabled={disabled}
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={6} md={6} display="flex" alignItems="flex-start">
        <Controller
          name={`${name}.max`}
          control={control}
          render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
            <EditTextField
              value={value}
              id={code}
              type="number"
              label="Valeur maximum numérique entière (%)"
              onBlur={onBlur}
              onChange={(e) => {
                clearRapportConseilErrors(fieldName, resetField, watch, clearErrors)
                onChange(e)
              }}
              fieldError={error}
              readOnly={readOnly}
              disabled={disabled}
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={12} display="flex" alignItems="flex-start">
        <Controller
          name={`${name}.commentaire`}
          control={control}
          render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
            <EditTextField
              fullWidth
              minRows={3}
              multiline
              id={code}
              value={value}
              onBlur={onBlur}
              onChange={(e) => {
                clearRapportConseilErrors(fieldName, resetField, watch, clearErrors)
                onChange(e)
              }}
              label="Commentaire"
              fieldError={error}
              readOnly={readOnly}
              disabled={disabled}
            />
          )}
        />
      </Grid>
    </Grid>
  )
}
