import { SelectOption } from 'plateforme/components'

export interface ParametrageType {
  code: string
  libelle: string
  actif: boolean
}

// keys used : DATES_HOSPITALISATION_IMPUTABLES, DATES_HOSPITALISATION_IMPUTABLES ,GENE_TEMPORAIRE_TOTALE
// DATES_ARRETS_DE_TRAVAIL_IMPUTABLES, TIERCE_PERSONNE_TEMPORAIRE, TIERCE_PERSONNE_DEFINITIVE
// GENE_TEMPORAIRE_PARTIELLE_CLASSE_1, GENE_TEMPORAIRE_PARTIELLE_CLASSE_2, GENE_TEMPORAIRE_PARTIELLE_CLASSE_3, GENE_TEMPORAIRE_PARTIELLE_CLASSE_4
export interface DommagesPeriodesMax {
  [key: string]: number
}

export interface ParametrageEntrepriseCommon {
  canalWs?: boolean
  clientId?: string
  canalExtranet?: boolean
  notifiableParMail?: boolean
  mailFormatable?: boolean
  mailFormatObjet?: string
  lettreMission?: boolean
  cloisonnementService?: boolean
  handicapGrave?: boolean
  precisionsMissionMax?: number
  tiercePersonneDefinitivePeriodeSansFin?: TypeTiercePersonneDefinitivePeriodeSansFin
  tiercePersonnePeriodeAvecType?: boolean
  services?: ServiceEntreprise[]
  refVictimeEntreprise?: boolean
  assure?: boolean
  expertiseSurPiece?: boolean
  relanceExpertiseSurPiece?: boolean
  intervenantOccasionnel?: boolean
  messagerieMission?: boolean
  domainesContractuels?: DomaineContractuelEntreprise[]
  contacts?: Contacts
  delaisRelance?: DelaisRelance
  notifications?: Notifications
  refMissionEntrepriseObligatoire?: boolean
  remissionnementCarence?: RemissionnementCarence
  remissionnementConclusionProvisoire?: RemissionnementConclusionProvisoire
  mailReseauMedecin?: string
  plagesIpAutoriseesExtranet?: string[]
  plagesIpAutoriseesWs?: string[]
}

export interface DommagesCommentairesMax {
  DATES_HOSPITALISATION_IMPUTABLES?: number
  DATES_ARRETS_DE_TRAVAIL_IMPUTABLES?: number
}

export interface RemissionnementCarence {
  typeAuto?: TypeRemissionementAutoCarence
}

export interface RemissionnementConclusionProvisoire {
  typeAuto?: TypeRemissionnementAutoConclusionProvisoire
  tauxAIPP?: number
}

export enum TypeRemissionementAutoCarence {
  JAMAIS = 'JAMAIS',
  TOUJOURS = 'TOUJOURS',
  UNE_FOIS = 'UNE_FOIS',
}

export enum TypeTiercePersonneDefinitivePeriodeSansFin {
  ACTIF = 'ACTIF',
  INACTIF = 'INACTIF',
  OPTIONNEL = 'OPTIONNEL',
}

export enum TypeRemissionnementAutoConclusionProvisoire {
  JAMAIS = 'JAMAIS',
  TOUJOURS = 'TOUJOURS',
  TAUX_AIPP = 'TAUX_AIPP',
}

export interface Contacts {
  BIRC?: ContactInformations
  RCGE?: ContactInformations
  BNIR?: ContactInformations
  RCMD?: ContactInformations
  ASSC?: ContactInformations
  PREV?: ContactInformations
  ACCV?: ContactInformations
  FAUT?: ContactInformations
  AUTR?: ContactInformations
}

export interface ContactInformations {
  code?: CodeContact
  libelle?: string
  mail?: string
  telephone?: string
}

export enum CodeContact {
  CODE_BAL_ENTREPRISE = 'CG',
  CODE_BAL_SERVICE = 'SV',
  CODE_BAL_PERSONNALISE = 'PE',
  CODE_BAL_GESTIONNAIRE = 'GE',
}

export interface NotificationsCodes {
  [CodeContact.CODE_BAL_ENTREPRISE]: number // GL
  [CodeContact.CODE_BAL_SERVICE]: number // SV
  [CodeContact.CODE_BAL_GESTIONNAIRE]: number // GE
}

export interface ServiceEntreprise extends ParametrageType {
  mail: string
  telephone?: string
  statutLabel?: string
  registered?: boolean
}

export interface FichesConclusions {
  BIRC?: TypesFichesConclusions
  RCGE?: TypesFichesConclusions
  BNIR?: TypesFichesConclusions
  RCMD?: TypesFichesConclusions
  ASSC?: TypesFichesConclusions
  PREV?: TypesFichesConclusions
  ACCV?: TypesFichesConclusions
  FAUT?: TypesFichesConclusions
  AUTR?: TypesFichesConclusions
}

export enum EnumTypesFichesConclusions {
  CONV = 'CONV',
  SIMP = 'SIMP',
}

export enum CodeFichesConclusions {
  expertiseUnilaterale = '01',
  expertiseConjointe = '02',
  expertiseJudiciaire = '03',
  arbitrage = '04',
  avisTechnique = '05',
}

export interface TypesFichesConclusions {
  [CodeFichesConclusions.expertiseUnilaterale]: EnumTypesFichesConclusions
  [CodeFichesConclusions.expertiseConjointe]: EnumTypesFichesConclusions
  [CodeFichesConclusions.expertiseJudiciaire]: EnumTypesFichesConclusions
  [CodeFichesConclusions.arbitrage]: EnumTypesFichesConclusions
  [CodeFichesConclusions.avisTechnique]?: EnumTypesFichesConclusions
}

export interface DomaineContractuelEntreprise extends ParametrageType {
  dommages?: string[]
  statutLabel?: string
  registered?: boolean
}

export interface DelaisRelance {
  DELAI_REPONSE_MISSION: NaturesEvenementsDelais
  DELAI_REPONSE_SUIVI_MISSION: NaturesEvenementsDelais
  DELAI_VALIDATION_RAPPORT: NaturesEvenementsDelais
  DELAI_VALIDATION_DEMANDE_MODIFICATION: NaturesEvenementsDelais
}

export interface NaturesEvenementsDelais {
  BIRC?: Delais
  RCGE?: Delais
  BNIR?: Delais
  RCMD?: Delais
  ASSC?: Delais
  PREV?: Delais
  ACCV?: Delais
  FAUT?: Delais
  AUTR?: Delais
}

export interface Delais {
  [CodeFichesConclusions.expertiseUnilaterale]: number
  [CodeFichesConclusions.expertiseConjointe]: number
  [CodeFichesConclusions.expertiseJudiciaire]: number
  [CodeFichesConclusions.arbitrage]: number
  [CodeFichesConclusions.avisTechnique]?: number
}

export interface Notifications {
  REL_MIS_NREP: NotificationsCodes
  REL_DEXAM: NotificationsCodes
  REL_CONC: NotificationsCodes
  REL_FAC: NotificationsCodes
  REL_MAJ_CONC: NotificationsCodes
  DEP_DOC_MED: NotificationsCodes
  DEP_DOC_ENT: NotificationsCodes
  ACC_MIS: NotificationsCodes
  REF_MIS: NotificationsCodes
  MAJ_MIS_DEXAM: NotificationsCodes
  MAJ_MIS_SBLQ: NotificationsCodes
  DEP_CONC: NotificationsCodes
  DMD_MAJ_CONC: NotificationsCodes
  MAJ_CONC: NotificationsCodes
  DEP_FAC: NotificationsCodes
  ANN_FAC: NotificationsCodes
  REM_AUTO: NotificationsCodes
  PUR_AUTO: NotificationsCodes
  MEL_MED: NotificationsCodes
  SUP_DOC_MED: NotificationsCodes
  SUP_DOC_ENT: NotificationsCodes
  CLO_AUTO: NotificationsCodes
  PROP_MIS: NotificationsCodes
  ANN_MIS: NotificationsCodes
  ARR_MIS: NotificationsCodes
  SS_SUI_MIS: NotificationsCodes
  MAJ_FAC: NotificationsCodes
  MAJ_DOS: NotificationsCodes
  CREA_DOS: NotificationsCodes
  VAL_DOS: NotificationsCodes
  ANN_DOS: NotificationsCodes
  CLO_DOS: NotificationsCodes
  ROUV_DOS: NotificationsCodes
  PUR_DOS: NotificationsCodes
  CREA_HAB: NotificationsCodes
  MEL_MIS: NotificationsCodes
}

export interface ReferenceParametrageEntreprise {
  typesFichesConclusions?: FichesConclusions
  handicapGrave?: boolean
  tiercePersonneDefinitivePeriodeSansFin?: TypeTiercePersonneDefinitivePeriodeSansFin
  tiercePersonnePeriodeAvecType?: boolean
  dommagesPeriodesMax?: DommagesPeriodesMax
  dommagesCommentairesMax?: DommagesCommentairesMax
  messagerieMission?: boolean
}

export interface ParametrageEntreprise extends ReferenceParametrageEntreprise {
  canalWs?: boolean
  clientId?: string
  canalExtranet?: boolean
  notifiableParMail?: boolean
  mailFormatable?: boolean
  mailFormatObjet?: string
  lettreMission?: boolean
  cloisonnementService?: boolean
  services?: ServiceEntreprise[]
  refVictimeEntreprise?: boolean
  assure?: boolean
  expertiseSurPiece?: boolean
  precisionsMissionMax?: number
  commentaireDemandeModificationMissionMax?: number
  commentaireEvenementMax?: number
  commentaireExpertiseMax?: number
  masquerMotifBlocageDansMessageDataWsEntResponse?: boolean
  rdv?: boolean
  lieuExpertiseAvecADefinir?: boolean
  intervenantOccasionnel?: boolean
  domainesContractuels?: DomaineContractuelEntreprise[]
  contacts?: Contacts
  delaisRelance?: DelaisRelance
  notifications?: Notifications
  refMissionEntrepriseObligatoire?: boolean
  remissionnementCarence?: RemissionnementCarence
  remissionnementConclusionProvisoire?: RemissionnementConclusionProvisoire
  mailReseauMedecin?: string
  plagesIpAutoriseesExtranet?: string[]
  plagesIpAutoriseesWs?: string[]
}

export interface ReferentielEntreprise extends SelectOption {
  id: number // doit être utilisé que par la partie admin, PLEASE !!!
  code: string
  libelle: string
  codeACPR: string
  codeSIREN: string
  actif: boolean
  parametrage: ReferenceParametrageEntreprise
}

export default interface ProfilEntreprise {
  code?: string
  codeACPR?: string
  codeSIREN?: string
  libelle?: string
  adresse1?: string
  adresse2?: string
  adresse3?: string
  codePostal?: string
  commune?: string
  pays?: string
  labelPays?: string
  mail?: string
  telephone?: string
  statut?: string
  labelStatut?: string
  parametrage?: ParametrageEntreprise
}
