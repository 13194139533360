import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import {
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Switch,
} from '@mui/material'
import { EditTextField } from 'plateforme/components'

interface HandicapGravePartProps {
  readOnly?: boolean
}

export default function ParametrageDossierPart({ readOnly }: HandicapGravePartProps) {
  const { control } = useFormContext()

  return (
    <Card>
      <CardHeader title="Paramétrage des dossiers" />
      <CardContent>
        <Grid container>
          <Grid item xs={12} lg={12}>
            <Controller
              name="parametrage.commentaireEvenementMax"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <EditTextField
                  id="commentaireEvenementMax"
                  type="number"
                  fullWidth
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  fieldError={error}
                  readOnly={readOnly}
                  label={`Taille max du champ "commentaire" d'un événement`}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <Controller
              name="parametrage.commentaireExpertiseMax"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <EditTextField
                  id="commentaireExpertiseMax"
                  type="number"
                  fullWidth
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  fieldError={error}
                  readOnly={readOnly}
                  label={`Taille max du champ "commentaire" d'une expertise`}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <Controller
              name="parametrage.masquerMotifBlocageDansMessageDataWsEntResponse"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <FormControl fullWidth disabled={readOnly} error={!!error} variant="standard">
                  <FormControlLabel
                    id="masquerMotifBlocageDansMessageDataWsEntResponse"
                    value={value}
                    control={<Switch onChange={onChange} disabled={readOnly} onBlur={onBlur} checked={value} />}
                    label={`Masquer la récupération API (MessageDataWsEntResponse) du commentaire lié au motif de blocage "Autre" (à supprimer une fois le passage en V2.2)`}
                  />
                  <FormHelperText sx={{ marginLeft: '-35px' }}>{error?.message}</FormHelperText>
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
