import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useSnackbar } from 'notistack'
import { useDispatch, useSelector } from 'react-redux'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { SerializedError } from '@reduxjs/toolkit'
import { Grid, Stack, Tooltip } from '@mui/material'
import { AnnulerButton, EditTextField } from 'plateforme/components'
import { errorStatutIllegalNarrowed, trimToUndefined } from 'plateforme/services/utils'
import useErrorFormMapper, { IQueryErrorResponse } from 'plateforme/hooks/useErrorFormMapper'
import EnvoyerButton from 'plateforme/components/buttons/EnvoyerButton'
import AttacherPJButton from 'plateforme/components/buttons/AttacherPJButton'
import useConfirmDialog from 'plateforme/hooks/useConfirmDialog'
import DocumentTeleverse from 'plateforme/store/types/documentTeleverse'
import AjouterReferenceDocument from 'plateforme/parts/MessageriePart/AjoutReferenceDocument'
import DocumentSelectedCard from 'plateforme/parts/MessageriePart/DocumentSelectedCard'
import DossierEntreprise from 'assureur/store/types/dossierEntreprise'
import MissionEntreprise from 'assureur/store/types/missionEntreprise'
import { usePostMessageMissionMutation } from 'assureur/store/apis/messageEntrepriseApi'
import { MessageMissionRequest } from 'assureur/store/types/messageEntreprise'
import {
  SelectedDocumentEntreprise,
  getSelectedDocumentEntreprise,
  setSelectedDocument,
} from 'assureur/store/slices/document/selectedDocumentSlice'

type MessageFormProps = {
  dossier?: DossierEntreprise
  mission?: MissionEntreprise
  documents?: DocumentTeleverse[]
}

export default function MessageEntrepriseForm({ dossier, mission, documents }: MessageFormProps) {
  const { enqueueSnackbar } = useSnackbar()
  const { confirm, confirmDialog, closeConfirmDialog } = useConfirmDialog()
  const dispatch = useDispatch()
  const selectedDocument = useSelector(getSelectedDocumentEntreprise)
  const [postMessageMission, { error: errorMessage, isLoading }] = usePostMessageMissionMutation()

  const initialValues = {
    message: '',
  }
  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues: initialValues,
  })

  const {
    control,
    handleSubmit,
    reset,
    setError,
    getValues,
    formState: { isValid },
  } = methods

  useErrorFormMapper(errorMessage as IQueryErrorResponse, setError, getValues)

  const handleCancel = () => {
    reset(initialValues)
    dispatch(setSelectedDocument(undefined))
  }

  // handleClick : envoyer message

  const onSubmit = async (data: { message: string }) => {
    const body = {
      codeDossier: trimToUndefined(dossier?.code),
      codeMission: trimToUndefined(mission?.code),
      message: trimToUndefined(data.message),
      codeDocument: trimToUndefined(selectedDocument?.code),
    } as MessageMissionRequest

    await postMessageMission(body)
      .unwrap()
      .then(() => {
        enqueueSnackbar(`Le message a été envoyé`, { variant: 'success' })
        reset(initialValues)
        dispatch(setSelectedDocument(undefined))
      })
      .catch((error: FetchBaseQueryError | SerializedError | undefined) => {
        const errorValiderNarrowed = errorStatutIllegalNarrowed(true, error)
        if (errorValiderNarrowed) {
          enqueueSnackbar(`L'envoi de message a échoué : ${errorValiderNarrowed.message}`, { variant: 'error' })
        } else {
          enqueueSnackbar(`L'envoi de message a échoué`, { variant: 'error' })
        }
      })
  }

  const onAnnuler = () => {
    dispatch(setSelectedDocument(undefined))
    closeConfirmDialog()
  }

  const onSelectRow = (doc: SelectedDocumentEntreprise) => {
    dispatch(setSelectedDocument(doc))
  }

  const onAddDocumentRef = () => {
    confirm({
      title: `Ajouter une référence document`,
      confirmMsg: '',
      withForm: true,
      maxWidth: 'xl',
      fullWidth: true,
      form: (
        <AjouterReferenceDocument
          getSelectedDocument={getSelectedDocumentEntreprise}
          documents={documents}
          onAjouter={closeConfirmDialog}
          onAnnuler={onAnnuler}
          onSelectRow={onSelectRow}
        />
      ),
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="id-texto-input" name="form-texto-input">
      {confirmDialog}
      <Grid container padding={2} spacing={0}>
        <Grid item xs={12} sm={12} md={12}>
          <Controller
            name="message"
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <EditTextField
                id="id-texte-message"
                label="Message"
                fullWidth
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                fieldError={error}
                minRows={3}
                multiline
              />
            )}
          />
        </Grid>
        {selectedDocument && (
          <Grid item xs={12} sm={12} md={12}>
            <DocumentSelectedCard
              onDeleteDocument={() => {
                dispatch(setSelectedDocument(undefined))
              }}
              document={documents?.find((doc) => doc.code === selectedDocument.code)}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={12} md={12}>
          <Stack direction="row" justifyContent="space-between">
            <Tooltip
              title={
                !!selectedDocument &&
                `Veuillez supprimer le document ajouté au message au dessous a fin d'activer la sélection d'un nouveau document.`
              }
              placement="right"
            >
              {/* La div n'est ici que pour tricher, car sinon la tooltip ne s'affiche pas quand le bouton est disable */}
              <div>
                <AttacherPJButton color="secondary" disabled={!!selectedDocument} onClick={onAddDocumentRef}>
                  Ajouter référence document
                </AttacherPJButton>
              </div>
            </Tooltip>
            <Stack direction="row" justifyContent="right">
              <AnnulerButton onClick={handleCancel} disabled={!isValid} loading={isLoading}>
                Annuler
              </AnnulerButton>
              <EnvoyerButton type="submit" disabled={!isValid} loading={isLoading}>
                Envoyer le message
              </EnvoyerButton>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </form>
  )
}
