import React from 'react'
import {
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  Switch,
} from '@mui/material'

import { Controller, useFormContext } from 'react-hook-form'
import { TypeTiercePersonneDefinitivePeriodeSansFin } from 'plateforme/store/types/profilEntreprise'

interface TiercePersonnePartProps {
  readOnly?: boolean
}

export default function TiercePersonnePart({ readOnly }: TiercePersonnePartProps) {
  const { control } = useFormContext()

  return (
    <Card>
      <CardHeader title="Paramétrage des dommages" />
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <strong>Dommages avec tierce personne</strong>
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="parametrage.tiercePersonnePeriodeAvecType"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <FormControl fullWidth disabled={readOnly} error={!!error} variant="standard">
                  <FormControlLabel
                    id="tiercePersonnePeriodeAvecType"
                    value={value}
                    control={<Switch onChange={onChange} disabled={readOnly} onBlur={onBlur} checked={value} />}
                    label="Périodes avec type [active/passive] pour toute les tierces personnes ('type' est obligatoire si paramètre actif sinon il est optionnel)"
                  />
                  <FormHelperText sx={{ marginLeft: '-35px' }}>{error?.message}</FormHelperText>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <strong>Périodes sans fin pour les tierces personnes définitives</strong>
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="parametrage.tiercePersonneDefinitivePeriodeSansFin"
              control={control}
              render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                <FormControl
                  disabled={readOnly}
                  error={!!error}
                  variant="standard"
                  sx={{ minHeight: 55, padding: 0, marginX: 1.5, display: 'flex', alignItems: 'flex-start' }}
                >
                  <RadioGroup
                    row
                    aria-labelledby="tiercePersonneDefinitivePeriodeSansFin"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                  >
                    <FormControlLabel
                      value={TypeTiercePersonneDefinitivePeriodeSansFin.ACTIF}
                      control={
                        <Radio
                          sx={{
                            color: `${error ? 'red' : ''}`,
                          }}
                        />
                      }
                      label="Actif"
                      sx={{ minHeight: 55 }}
                    />
                    <FormControlLabel
                      value={TypeTiercePersonneDefinitivePeriodeSansFin.INACTIF}
                      control={
                        <Radio
                          sx={{
                            color: `${error ? 'red' : ''}`,
                          }}
                        />
                      }
                      label="Inactif"
                      sx={{ minHeight: 55 }}
                    />
                    <FormControlLabel
                      value={TypeTiercePersonneDefinitivePeriodeSansFin.OPTIONNEL}
                      control={
                        <Radio
                          sx={{
                            color: `${error ? 'red' : ''}`,
                          }}
                        />
                      }
                      label="Optionnel"
                      sx={{ minHeight: 55 }}
                    />
                  </RadioGroup>
                  <FormHelperText sx={{ margin: '3px 14px' }}>{error?.message}</FormHelperText>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            {`Date de fin 'au' est caché si paramètre actif, 'au' est
              obligatoire si paramètre inactif, 'au' est présent et non obligatoire si optionnel`}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
