import React from 'react'
import { useSnackbar } from 'notistack'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { FormControl, FormControlLabel, FormHelperText, Grid, Stack, Switch } from '@mui/material'
import {
  AnnulerButton,
  AreaLoading,
  DatePickerInput,
  DividerFormButton,
  EditTextField,
  LabelIconField,
  MessageAlert,
  Prompt,
  ReadOnlyTextField,
  SauvegarderButton,
  SelectInputReferentiel,
  SupprimerButton,
  ValiderButton,
} from 'plateforme/components'
import useErrorFormMapper, { IQueryErrorResponse } from 'plateforme/hooks/useErrorFormMapper'
import useConfirmDialog from 'plateforme/hooks/useConfirmDialog'
import { ReferentielDommage } from 'plateforme/store/types/referentiel'
import { LibelleRapport, TypeRapport } from 'plateforme/store/types/rapportConclusion'
import { formatDateFR } from 'plateforme/services/dates.services'
import { isEmpty } from 'plateforme/services/utils'
import {
  isRapportCarence,
  isRapportConclusionDefinitive,
  isRapportConclusionProvisoire,
  isValidationPrimaire,
  isValideDefinitivement,
  isFicheSimplifiee,
} from 'plateforme/services/rapport.services'
import { isRapportConseilActive } from 'plateforme/services/mission.services'
import ProfilMedecin from 'medecin/store/types/profilMedecin'
import {
  Dommages,
  ModeDommage,
  TypeDommage,
  TypePersonne,
  UniteEstimation,
} from 'medecin/store/types/rapportConclusionMedecin'
import DossierMedecin from 'medecin/store/types/dossierMedecin'
import MissionEntreprise from 'medecin/store/types/missionMedecin'
import { useGetRevisionDossierQuery, usePutSauvegarderConclusionMutation } from 'medecin/store/apis/dossierMedecinApi'
import { useGetReferentielEntrepriseQuery } from 'medecin/store/apis/referentielEntrepriseMedecinApi'
import DommagesFields from './DommagesFields/DommagesFields'
import ParametrageDommagesForm from './ParametrageDommagesForm/ParametrageDommagesForm'
import LesionSequellesFieldArray from './LesionSequellesFieldArray'
import {
  convertToDommageForm,
  prepareSauvegarderConclusionRequestFromDataConclusionForm as prepareSauvegarderConclusionRequest,
} from './utils'

interface FicheConclusionFormProps {
  dossier: DossierMedecin
  mission: MissionEntreprise
  profil: ProfilMedecin
  dommagePredefini: ReferentielDommage[]
  typeConclusion: TypeRapport.CONCLUSION_PROVISOIRE | TypeRapport.CONCLUSION_DEFINITIVE | TypeRapport.CARENCE
  libelleConclusion:
    | LibelleRapport.CONCLUSION_PROVISOIRE
    | LibelleRapport.CONCLUSION_DEFINITIVE
    | LibelleRapport.CARENCE
}

export interface FicheConclusionData {
  nomPersonneAExaminer: string | null
  prenomPersonneAExaminer: string | null
  dateNaissancePersonneAExaminer: Date | null
  lieuSignature: string | null
  dateSignature: Date | null
  consentement: boolean
  refConsentement: string | null
  signataire: string | null
  commentaireNouvelExamen: string | null
  commentaire: string | null
  commentaireRapportConseil: string | null
  dateConsolidationPro: Date | null
  dateConsolidationDef: Date | null
  dateExamen: Date | null
  delaiNouvelExamen: string | null
  lesions: LesionData[]
  lesionSequelles: LesionSequelleData[]
  dommages: DommagesData | null
  motifCarence: string | null
  remissionnementPreconise: boolean
  handicapGrave: boolean
}

export interface LesionData {
  code: string
}

export interface LesionSequelleData {
  lesion: string
  sequelle: string
}

export interface DommagesData {
  [key: string]: DommageData | undefined
}

export interface DommageData {
  codePrejudice: string
  code: string
  label: string
  commentaire: string | ''
  plageDeDates: PlageDeDateData | null
  periodes: TiercePersonneData[] | null
  valeur: number | number[] | '' | null
  min: number | '' | null
  max: number | '' | null
  typeDommage: TypeDommage.DEFINITIF | TypeDommage.TEMPORAIRE | null
  handicapGrave: boolean
  nonEvaluable: boolean | null
  rapportConseil: RapportConseilData | null
}

export interface RapportConseilData {
  commentaire: string | ''
  valeur: number | number[] | '' | null
  min: number | null
  max: number | null
}

export interface PlageDeDateData {
  mode: ModeDommage | null
  periodes: PeriodeData[] | null
  min: EstimationData | null
  max: EstimationData | null
  estimation: EstimationData | null
}

export interface TiercePersonneData {
  type: keyof typeof TypePersonne | null
  nombreHeures: number | ''
  par: keyof typeof UniteEstimation | null
  periode: PeriodeData | null
}

export interface PeriodeData {
  du: Date | null
  au: Date | null
}

export interface EstimationData {
  valeur: number | ''
  unite: keyof typeof UniteEstimation | null
}

const formatInitDommages = (dommages?: Dommages, dommagesPredefini?: ReferentielDommage[]): DommagesData => {
  if (dommages !== undefined && !isEmpty(dommages)) {
    const dommageFormArray = Object.keys(dommages)
      .filter((code) => dommages[code])
      .map((code) => {
        const dommage = dommages[code]
        const dommagePredefini = dommagesPredefini?.find((df) => df.code === code)
        return convertToDommageForm(dommage, dommagePredefini)
      })
    return Object.fromEntries(dommageFormArray.map((df) => [df.code, df])) as DommagesData
  }
  return initDommages(dommagesPredefini)
}

const initDommages = (dommagesPredefini?: ReferentielDommage[]): DommagesData => {
  const refDommageFraisMedicaux = dommagesPredefini?.find((rd) => rd.code === 'FRAIS_MEDICAUX_ET_PARAMEDICAUX')
  if (refDommageFraisMedicaux !== undefined) {
    return {
      FRAIS_MEDICAUX_ET_PARAMEDICAUX: {
        codePrejudice: refDommageFraisMedicaux.codePrejudice,
        code: refDommageFraisMedicaux.code,
        label: refDommageFraisMedicaux.label,
        typeDommage: refDommageFraisMedicaux.typeDommage,
        handicapGrave: refDommageFraisMedicaux.handicapGrave,
        commentaire: '',
        nonEvaluable: false,
        rapportConseil: {
          commentaire: '',
        },
      } as DommageData,
    } as DommagesData
  }
  return {} as DommagesData
}

export default function FicheRapportMedecinForm({
  dossier,
  mission,
  profil,
  dommagePredefini,
  typeConclusion,
  libelleConclusion,
}: FicheConclusionFormProps) {
  // get props:
  const { code: codeDossier, refDossierEntreprise } = dossier
  const { code: codeMission, rapport } = mission

  const rapportConseilActive = !!isRapportConseilActive(typeConclusion, mission)

  if (codeDossier === undefined || codeMission === undefined) {
    throw new Error(`Aucun mission associée n'a été trouvée`)
  }

  const isDomainContractuel = (dossier?.expertise?.domaineContractuel?.dommages?.length ?? 0) > 0

  // local props
  const rapportModifiable = mission?.rapportModifiable ?? false
  const readOnly = !rapportModifiable || isValidationPrimaire(rapport)
  const showHandicapGrave =
    !isFicheSimplifiee(rapport) && !isRapportCarence(typeConclusion) && mission?.handicapGrave !== undefined

  // used hooks:
  const { data: dossierRevision, isLoading } = useGetRevisionDossierQuery({
    codeDossier,
    codeMission,
  })

  const { data: entreprises } = useGetReferentielEntrepriseQuery()
  const entreprise = entreprises?.find((e) => e.code === dossier.entreprise?.code)

  const { confirm, closeConfirmDialog, confirmDialog } = useConfirmDialog()
  const { enqueueSnackbar } = useSnackbar()
  const [putSauvegarderConclusion, { error: errorSauvegarder, isLoading: isLoadingSauvegarder }] =
    usePutSauvegarderConclusionMutation()

  // useFormHook du formulaire globale :
  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues: {
      nomPersonneAExaminer: rapport?.nomPersonneAExaminer ?? '',
      prenomPersonneAExaminer: rapport?.prenomPersonneAExaminer ?? '',
      dateNaissancePersonneAExaminer: rapport?.dateNaissancePersonneAExaminer ?? null,
      commentaires: rapport?.commentaire ?? '',
      commentaireRapportConseil: rapport?.commentaireRapportConseil ?? '',
      lieuSignature: profil?.lieuxExercice[0]?.commune ?? '',
      dateSignature: rapport?.dateSignature ?? null,
      consentement: rapport?.consentement ?? false,
      refConsentement: rapport?.refConsentement ?? '',
      signataire: rapport?.signataire ?? profil?.parametrage?.signature ?? '',
      commentaireNouvelExamen: rapport?.commentaireNouvelExamen ?? '',
      commentaire: rapport?.commentaire ?? '',
      dateConsolidationPro: rapport?.dateConsolidationPro ?? null,
      dateConsolidationDef: rapport?.dateConsolidationDef ?? null,
      delaiNouvelExamen: rapport?.delaiNouvelExamen ?? null,
      dateExamen: rapport?.dateExamen ?? null,
      lesions: rapport?.lesions?.map((code: string) => ({ code } as LesionData)) || [],
      lesionSequelles:
        rapport?.lesionSequelles?.map(({ lesion, sequelle }) => ({ lesion, sequelle } as LesionSequelleData)) ?? [],
      dommages: isFicheSimplifiee(rapport) ? null : formatInitDommages(rapport?.dommages, dommagePredefini),
      motifCarence: rapport?.motifCarence ?? null,
      remissionnementPreconise: rapport?.remissionnementPreconise ?? false,
      handicapGrave: rapport?.handicapGrave ?? mission?.handicapGrave ?? false,
    } as FicheConclusionData,
  })

  const {
    control,
    setError,
    formState: { isValid, isDirty },
    getValues,
    clearErrors,
    resetField,
    setValue,
    reset,
  } = methods

  const consentement = getValues('consentement')
  const handicapGrave = getValues('handicapGrave')
  const dommagesForm = getValues('dommages') as DommagesData

  // mapping des erreurs back
  const errorPost = errorSauvegarder as IQueryErrorResponse
  useErrorFormMapper(errorPost, setError, getValues)

  // Les fonctions de sauvegarde partiel et finale :
  const sauvegarder = async (validation: boolean, cancelValidation = false) => {
    const dataConclusionForm = getValues() as FicheConclusionData
    const request = prepareSauvegarderConclusionRequest(
      codeDossier,
      codeMission,
      typeConclusion,
      validation,
      rapportConseilActive,
      dataConclusionForm,
      showHandicapGrave,
      entreprise?.parametrage
    )
    await putSauvegarderConclusion(request)
      .unwrap()
      .then(() => {
        enqueueSnackbar(
          cancelValidation
            ? `La validation de votre saisie ${libelleConclusion} est annulée`
            : `Votre saisie ${libelleConclusion} est ${validation ? 'validée' : 'sauvegardée'}`,
          { variant: 'success' }
        )
        // NOTE: reset the form and specially the state isDirty, to unblock navigation between tabs in case success
        reset(
          {
            ...getValues(),
          },
          {
            keepValues: true,
            keepDirty: false,
          }
        )
      })
      .catch((error) => {
        enqueueSnackbar(error?.data?.message, { variant: 'error' })
      })
  }
  const onSauvegarder = async () => sauvegarder(false)
  const onSauvegarderAvecAnnulation = async () => sauvegarder(false, true)
  const onSauvegarderAvecValidation = async () => sauvegarder(true)

  const canDisplayReferenceConsentement = (refConsentement: string | null | undefined) => {
    return refConsentement && refConsentement.length > 0
  }

  const handicapGraveDommages = () => {
    return Object.keys(dommagesForm)
      .filter((code) => dommagesForm[code] as DommageData)
      .map((code) => dommagesForm[code] as DommageData)
      .filter((dommage) => dommage.handicapGrave)
  }

  const needRemoveHandicapGraveDommages = () => {
    return handicapGraveDommages().length > 0
  }

  const removeHandicapGraveDommages = () => {
    handicapGraveDommages().forEach((dommage) => {
      clearErrors(`dommages.${dommage.code}`)
      resetField(`dommages.${dommage.code}`)
      setValue(`dommages.${dommage.code}`, undefined)
    })
  }

  const msgHandicapGraveDommages = () => {
    return (
      <>
        Cette action va entraîner la suppression des dommages :
        <ul style={{ margin: 0, paddingLeft: 24 }}>
          {handicapGraveDommages()?.map((dommage) => (
            <li>{dommage.label}</li>
          ))}
        </ul>
        <br />
        Êtes-vous sûr de vouloir continuer ?
      </>
    )
  }

  const suffixePrevisibles = isRapportConclusionProvisoire(typeConclusion) ? ' prévisibles' : ''
  const isDommageSelectionAllowed = (typeDomage: TypeDommage.DEFINITIF | TypeDommage.TEMPORAIRE) => {
    return dommagePredefini.some((d) => d.typeDommage === typeDomage)
  }

  if (isLoading) {
    return <AreaLoading height={335} />
  }
  return (
    <FormProvider {...methods}>
      <form id="form-save-conclusion" style={{ width: '100%' }}>
        <Prompt
          title="Actualiser l'Application TRANSMED ?"
          msgConfirmation="Les modifications que vous avez apportées ne seront peut-être pas enregistrées."
          when={isDirty}
        />
        <Grid container direction="row">
          {(isRapportConclusionProvisoire(typeConclusion) || isRapportConclusionDefinitive(typeConclusion)) &&
            consentement && (
              <Grid
                item
                xs={12}
                md={canDisplayReferenceConsentement(rapport?.refConsentement) ? 6 : 12}
                lg={canDisplayReferenceConsentement(rapport?.refConsentement) ? 6 : 12}
                display="flex"
                alignItems="center"
              >
                <LabelIconField iconType="check" label="Consentement recueilli" />
              </Grid>
            )}
          {(isRapportConclusionProvisoire(typeConclusion) || isRapportConclusionDefinitive(typeConclusion)) &&
            canDisplayReferenceConsentement(rapport?.refConsentement) && (
              <Grid item xs={12} md={6} lg={6}>
                <Controller
                  name="refConsentement"
                  control={control}
                  render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                    <EditTextField
                      fullWidth
                      id="ref-consentement"
                      label="Référence du recueil de consentement"
                      value={value}
                      onBlur={onBlur}
                      onChange={onChange}
                      readOnly
                      fieldError={error}
                    />
                  )}
                />
              </Grid>
            )}

          <Grid item xs={12} md={6} lg={4}>
            <ReadOnlyTextField
              fullWidth
              id="reference-dossier-entreprise"
              /* value={`${libelle}${refMissionEntreprise ? ` - ${refMissionEntreprise}` : ''}`} */
              /* value={libelleMissionEntreprise} */
              value={refDossierEntreprise}
              label="Référence dossier entreprise"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <ReadOnlyTextField
              fullWidth
              id="date-evenement"
              value={formatDateFR(dossierRevision?.evenement?.date)}
              label="Date de l'événement"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <SelectInputReferentiel
              fullWidth
              id="cadre-expertise"
              label="Cadre de l'expertise"
              referentielName="cadresExpertise"
              value={dossierRevision?.expertise?.cadreExpertise || ''}
              readOnly
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Controller
              name="nomPersonneAExaminer"
              control={control}
              render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                <EditTextField
                  fullWidth
                  id="nom-personne-a-examiner"
                  label="Nom de la personne à examiner"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  readOnly={readOnly}
                  fieldError={error}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Controller
              name="prenomPersonneAExaminer"
              control={control}
              render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                <EditTextField
                  fullWidth
                  id="prenom-personne-a-examiner"
                  label="Prénom de la personne à examiner"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  readOnly={readOnly}
                  fieldError={error}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Controller
              name="dateNaissancePersonneAExaminer"
              control={control}
              render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                <DatePickerInput
                  InputProps={{
                    id: 'date-naissance-personne-a-examiner',
                    fullWidth: true,
                  }}
                  label="Date de naissance de la personne à examiner"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  readOnly={readOnly}
                  fieldError={error}
                />
              )}
            />
          </Grid>

          {isRapportCarence(typeConclusion) && (
            <>
              <Grid item xs={12} md={6} lg={4}>
                <Controller
                  name="motifCarence"
                  control={control}
                  render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                    <SelectInputReferentiel
                      readOnly={readOnly}
                      id="motif-carence"
                      label="Motif de carence"
                      referentielName="motifCarence"
                      value={value}
                      onBlur={onBlur}
                      onChange={onChange}
                      fullWidth
                      withNoSelectionItem
                      fieldError={error}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} display="flex" alignItems="center">
                <Controller
                  name="remissionnementPreconise"
                  control={control}
                  render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                    <FormControl disabled={readOnly} error={!!error} variant="standard">
                      <FormControlLabel
                        id="remissionnement-preconise"
                        value={value}
                        control={<Switch checked={value} onBlur={onBlur} onChange={onChange} />}
                        label="Préconisation médecin: remissionnement"
                      />
                      <FormHelperText>{error?.message}</FormHelperText>
                    </FormControl>
                  )}
                />
              </Grid>
            </>
          )}
          {isRapportConclusionDefinitive(typeConclusion) && (
            <Grid item xs={12} md={6} lg={4}>
              <Controller
                name="dateConsolidationDef"
                control={control}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <DatePickerInput
                    InputProps={{
                      id: 'date-consolidation',
                      fullWidth: true,
                    }}
                    label="Date de consolidation"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    readOnly={readOnly}
                    fieldError={error}
                  />
                )}
              />
            </Grid>
          )}
          {(isRapportConclusionDefinitive(typeConclusion) || isRapportConclusionProvisoire(typeConclusion)) && (
            <Grid item xs={12} md={6} lg={4}>
              <Controller
                name="dateExamen"
                control={control}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <DatePickerInput
                    InputProps={{
                      id: 'date-examen',
                      fullWidth: true,
                    }}
                    label="Date d'examen"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    readOnly={readOnly}
                    fieldError={error}
                  />
                )}
              />
            </Grid>
          )}
          {isRapportConclusionProvisoire(typeConclusion) && (
            <>
              <Grid item xs={12} md={6} lg={4}>
                <Controller
                  name="delaiNouvelExamen"
                  control={control}
                  render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                    <SelectInputReferentiel
                      readOnly={readOnly}
                      id="delai-nouvel-examen"
                      label="Délai nouvel examen"
                      referentielName="delaiNouvelExamen"
                      value={value}
                      onBlur={onBlur}
                      onChange={onChange}
                      fullWidth
                      withNoSelectionItem
                      fieldError={error}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Controller
                  name="dateConsolidationPro"
                  control={control}
                  render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                    <DatePickerInput
                      InputProps={{
                        id: 'date-previsible-consolidation',
                        fullWidth: true,
                      }}
                      label="Date prévisionnelle de consolidation"
                      value={value}
                      onBlur={onBlur}
                      onChange={onChange}
                      readOnly={readOnly}
                      fieldError={error}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="commentaireNouvelExamen"
                  control={control}
                  render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                    <EditTextField
                      fullWidth
                      minRows={4}
                      multiline
                      id="commentaire-conclusion-pro"
                      label="Commentaire nouvel examen / date de consolidation"
                      value={value}
                      onBlur={onBlur}
                      onChange={onChange}
                      readOnly={readOnly}
                      fieldError={error}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} display="flex" alignItems="left">
                <Controller
                  name="remissionnementPreconise"
                  control={control}
                  render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                    <FormControl disabled={readOnly} error={!!error} variant="standard">
                      <FormControlLabel
                        id="remissionnement-preconise"
                        value={value}
                        control={<Switch checked={value} onBlur={onBlur} onChange={onChange} />}
                        label="Préconisation médecin: remissionnement"
                      />
                      <FormHelperText>{error?.message}</FormHelperText>
                    </FormControl>
                  )}
                />
              </Grid>
            </>
          )}
          {showHandicapGrave && (
            <Grid item xs={12} display="flex" alignItems="left">
              <Controller
                name="handicapGrave"
                control={control}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <FormControl disabled={readOnly} error={!!error} variant="standard">
                    <FormControlLabel
                      id="handicap-grave"
                      value={value}
                      control={
                        <Switch
                          checked={value}
                          onBlur={onBlur}
                          onChange={(e) => {
                            if (!e.target.checked && needRemoveHandicapGraveDommages()) {
                              confirm({
                                title: 'Désactivation handicap grave',
                                confirmMsg: msgHandicapGraveDommages(),
                                variant: 'confirm',
                                withForm: true,
                                form: (
                                  <Grid container paddingTop={0} width="500px">
                                    <Grid justifyContent="flex-end" container item xs={12} columnSpacing={2}>
                                      <Grid item>
                                        <AnnulerButton
                                          onClick={() => {
                                            onChange(true)
                                            closeConfirmDialog()
                                          }}
                                        >
                                          Annuler
                                        </AnnulerButton>
                                      </Grid>
                                      <Grid item>
                                        <SupprimerButton
                                          onClick={() => {
                                            onChange(false)
                                            closeConfirmDialog()
                                            removeHandicapGraveDommages()
                                          }}
                                          autoFocus
                                        >
                                          Confirmer
                                        </SupprimerButton>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                ),
                              })
                            } else {
                              onChange(e)
                            }
                          }}
                        />
                      }
                      label="Conclusions de type handicap grave"
                    />
                    <FormHelperText>{error?.message}</FormHelperText>
                  </FormControl>
                )}
              />
            </Grid>
          )}
          {!isFicheSimplifiee(rapport) && !isRapportCarence(typeConclusion) && (
            <Grid container item>
              {confirmDialog}
              {isDommageSelectionAllowed(TypeDommage.TEMPORAIRE) && (
                <>
                  <Grid item xs={12}>
                    <DividerFormButton
                      readOnly={readOnly}
                      title="Dommages temporaires"
                      tooltip="Sélectionnez les dommages temporaires"
                      addAction={() =>
                        confirm({
                          maxWidth: 'md',
                          fullWidth: true,
                          withForm: true,
                          form: (
                            <ParametrageDommagesForm
                              typeDommage={TypeDommage.TEMPORAIRE}
                              onClose={closeConfirmDialog}
                              dommagePredefini={dommagePredefini}
                              showHandicapGrave={showHandicapGrave}
                              handicapGrave={handicapGrave}
                              isDomainContractuel={isDomainContractuel}
                            />
                          ),
                          confirmMsg: `Veuillez sélectionner les dommages temporaires concernant votre fiche de ${libelleConclusion}`,
                          title: `Sélectionnez des dommages temporaires`,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DommagesFields
                      readOnly={readOnly}
                      typeDommage={TypeDommage.TEMPORAIRE}
                      typeConclusion={typeConclusion}
                      parametrage={entreprise?.parametrage}
                      isRapportConseilActive={rapportConseilActive}
                      libelleConclusion={libelleConclusion}
                    />
                  </Grid>
                </>
              )}
              {isDommageSelectionAllowed(TypeDommage.DEFINITIF) && (
                <>
                  <Grid item xs={12}>
                    <DividerFormButton
                      readOnly={readOnly}
                      title={`Dommages permanents${suffixePrevisibles}`}
                      tooltip={`Sélectionnez les dommages permanents${suffixePrevisibles}`}
                      addAction={() =>
                        confirm({
                          maxWidth: 'md',
                          fullWidth: true,
                          withForm: true,
                          form: (
                            <ParametrageDommagesForm
                              typeDommage={TypeDommage.DEFINITIF}
                              onClose={closeConfirmDialog}
                              dommagePredefini={dommagePredefini}
                              showHandicapGrave={showHandicapGrave}
                              handicapGrave={handicapGrave}
                              isDomainContractuel={isDomainContractuel}
                            />
                          ),
                          confirmMsg: `Veuillez sélectionner les dommages permanents${suffixePrevisibles} concernant votre fiche de ${libelleConclusion} :`,
                          title: `Sélectionnez des dommages permanents${suffixePrevisibles}`,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DommagesFields
                      readOnly={readOnly}
                      typeDommage={TypeDommage.DEFINITIF}
                      typeConclusion={typeConclusion}
                      parametrage={entreprise?.parametrage}
                      isRapportConseilActive={rapportConseilActive}
                      libelleConclusion={libelleConclusion}
                    />
                  </Grid>
                </>
              )}
              {(isRapportConclusionDefinitive(typeConclusion) || isRapportConclusionProvisoire(typeConclusion)) && (
                <LesionSequellesFieldArray readOnly={readOnly} />
              )}
            </Grid>
          )}
          {(isRapportConclusionDefinitive(typeConclusion) ||
            isRapportConclusionProvisoire(typeConclusion) ||
            isRapportCarence(typeConclusion)) && (
            <>
              <Grid item xs={12}>
                <MessageAlert iconCenter backgroundColor="white">
                  Ce commentaire n&apos;est pas confidentiel, il sera affiché sur la fiche de conclusions
                </MessageAlert>
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="commentaire"
                  control={control}
                  render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                    <EditTextField
                      fullWidth
                      minRows={4}
                      multiline
                      id="commentaire-def"
                      label="Commentaire fiche de conclusions"
                      value={value}
                      onBlur={onBlur}
                      onChange={onChange}
                      readOnly={readOnly}
                      fieldError={error}
                    />
                  )}
                />
              </Grid>
            </>
          )}
          {rapportConseilActive && (
            <>
              <Grid item xs={12}>
                <MessageAlert iconCenter backgroundColor="white">
                  Ce commentaire n&apos;est pas confidentiel, il sera affiché sur le rapport de conseil suite
                  conclusions
                </MessageAlert>
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="commentaireRapportConseil"
                  control={control}
                  render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                    <EditTextField
                      fullWidth
                      minRows={4}
                      multiline
                      id="commentaire-rapport-conseil"
                      label="Commentaire rapport de conseil"
                      value={value}
                      onBlur={onBlur}
                      onChange={(e) => {
                        onChange(e)
                      }}
                      readOnly={readOnly}
                      fieldError={error}
                    />
                  )}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} md={6} lg={4}>
                <Controller
                  name="dateSignature"
                  control={control}
                  render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                    <DatePickerInput
                      InputProps={{
                        id: 'date-signature',
                        fullWidth: true,
                      }}
                      label="Fait le"
                      value={value}
                      onBlur={onBlur}
                      onChange={onChange}
                      readOnly={readOnly}
                      fieldError={error}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Controller
                  name="lieuSignature"
                  control={control}
                  render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                    <EditTextField
                      fullWidth
                      multiline
                      id="lieu-signature"
                      label="À"
                      value={value}
                      onBlur={onBlur}
                      onChange={onChange}
                      readOnly={readOnly}
                      fieldError={error}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={4}>
                <Controller
                  name="signataire"
                  control={control}
                  render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                    <EditTextField
                      fullWidth
                      multiline
                      id="signataire"
                      label="Par"
                      value={value}
                      onBlur={onBlur}
                      onChange={onChange}
                      readOnly={readOnly}
                      fieldError={error}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {!isValideDefinitivement(rapport) && rapportModifiable && (
          <Grid item xs={12}>
            <Stack paddingTop={3.5} direction="row" alignItems="center" justifyContent="end">
              {isValidationPrimaire(rapport) ? (
                <AnnulerButton onClick={onSauvegarderAvecAnnulation} loading={isLoadingSauvegarder}>
                  Annuler et modifier
                </AnnulerButton>
              ) : (
                <>
                  <SauvegarderButton color="secondary" onClick={onSauvegarder} loading={isLoadingSauvegarder}>
                    Sauvegarder la fiche
                  </SauvegarderButton>
                  <ValiderButton
                    disabled={!isValid}
                    onClick={onSauvegarderAvecValidation}
                    loading={isLoadingSauvegarder}
                  >
                    Valider la fiche
                  </ValiderButton>
                </>
              )}
            </Stack>
          </Grid>
        )}
      </form>
    </FormProvider>
  )
}
